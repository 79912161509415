<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl mt-10 p-2"
      >
        <div class="grid grid-cols-1 gap-2 lg:grid-cols-7 text-gray-300">
          <div class="sm:col-span-1 col-span-4 mt-1 sm:mt-10">
            <div style="font-size: 14px" :class="showMenu">
              <div>
                <div>
                  <h3
                    class="text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 via-purple-500 to-blue-500"
                  >
                    Sort by
                  </h3>
                </div>
                <div class="mt-2 grid grid-cols-3 gap-0 lg:grid-cols-1">
                  <div class="" v-for="m in mode" :key="m">
                    <input
                      type="checkbox"
                      class="cursor-pointer"
                      v-model="m.value"
                      @click="changeMode(m.name)"
                    />
                    {{ m.name }}
                  </div>
                </div>
              </div>

              <div class="mt-5 sm:mt-10">
                <div>
                  <h3
                    class="text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 via-purple-500 to-blue-500"
                  >
                    Sort by
                  </h3>
                </div>
                <div class="mt-2 grid grid-cols-3 gap-0 lg:grid-cols-1">
                  <div v-for="m in brand" :key="m">
                    <input
                      type="checkbox"
                      class="cursor-pointer"
                      v-model="m.value"
                      @click="changeBrand(m.name)"
                    />
                    {{ m.name }}
                  </div>
                </div>
              </div>

              <div class="mt-5 sm:mt-10">
                <div>
                  <h3
                    class="text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 via-purple-500 to-blue-500"
                  >
                    Categories
                  </h3>
                </div>
                <div class="mt-2 grid grid-cols-3 gap-0 lg:grid-cols-1">
                  <div v-for="m in category" :key="m">
                    <input
                      type="checkbox"
                      class="cursor-pointer"
                      v-model="m.value"
                      @click="changeCategory(m.name)"
                    />
                    {{ m.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-6 sm:mt-1 mt-1">
            <div class="flex">
              <div class="flex-none">
                <div class="lg:hidden md:block text-gray-200">
                  <button
                    class="flex-none text-sm px-6 py-2 border rounded-xl text-gray-200 border-none bg-gradient-to-r from-blue-600 to-green-500 bg-opacity-25"
                    v-on:click="changeMenu()"
                  >
                    Sort Prompt
                  </button>
                </div>
              </div>

              <div class="flex-grow text-right">
                <a href="/publicFree">
                  <button
                    class="flex-none text-sm px-6 py-2 border rounded-xl text-gray-200 border-none bg-gradient-to-r from-green-500 to-blue-600 bg-opacity-25"
                  >
                    <i class="fas fa-plus mrs-3"></i>

                    Public Prompt
                  </button>
                </a>
              </div>
            </div>

            <h3
              class="sm:mt-1 mt-7 text-xl font-bold xl:text-xl text-transparent bg-clip-text bg-gradient-to-r from-green-400 via-purple-500 to-blue-500 transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer"
            >
              {{ textMode }} Prompts
            </h3>

            <form
              v-on:submit.prevent="searchPromptByKeywords"
              class="flex justify-center border-2 py-1 sm:py-3 px-2 rounded-lg max-w-xl mt-2 bg-gray-600"
            >
              <input
                placeholder="Search AI images"
                v-model="searchPrompt"
                class="flex-grow outline-none text-sm bg-gray-600 text-gray-100"
              />

              <button>
                <img
                  class="w-7 cursor-pointer"
                  src="@/assets/gif/zoom.gif"
                  alt=""
                />
              </button>
            </form>

            <h3
              class="mt-2 text-xs font-bold transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer"
              v-if="addressSearch"
            >
              Search:
              <b class="text-blue-300">
                {{ addressSearch }} ...

                <a href="/search" class="text-red-500"> X </a>
              </b>
            </h3>

            <hr class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700" />
            <div class="grid grid-cols-2 gap-2 lg:grid-cols-3 md:grid-cols-2">
              <div
                class="transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer rounded"
                style="margin-top: 5px"
                v-for="s in prompts"
                :key="s"
              >
                <div
                  class="p-0.5 bg-opacity-25 bg-gradient-to-r from-purple-400 to-pink-400 rounded-lg"
                >
                  <div class="text-sm border-none rounded">
                    <a class="" :href="linkDetail(s)">
                      <div
                        class="rounded-t-lg bg-cover bg-center h-52 md:h-80 lg:h-80 w-full inset-0 object-cover ease-in-out opacity-100 opacity-100 hover:opacity-0 border-b"
                        :style="'background-image: url(' + s.img_1 + ')'"
                      >
                        <div class="h-full w-ful">
                          <div class="flex p-2">
                            <div
                              class="flex border rounded bg-blue-600 bg-opacity-75"
                            >
                              <div class="text-xs p-1 text-gray-100">
                                <i class="fa fa-check-square text-gray-200"></i>
                                {{ s.prompt_type }}
                              </div>
                            </div>
                          </div>

                          <div class="hidden lg:block">
                            <a
                              class="flex items-end justify-center w-full h-36 md:h-40 lg:h-72"
                              :href="'/account/' + s.address"
                            >
                              <div
                                class="flex border-2 border-blue-300 p-2 rounded-md bg-gray-700 bg-opacity-90"
                              >
                                <img
                                  src="https://promptdue.com/tokens/BNB.png"
                                  class="w-5"
                                />
                                <b
                                  class="ml-1 text-gray-100"
                                  v-if="s.address != 'free'"
                                >
                                  {{ s.address.slice(0, 5) }} ...
                                  {{
                                    s.address.slice(
                                      s.address.length - 6,
                                      s.address.length - 1
                                    )
                                  }}</b
                                >
                                <b class="ml-1 text-gray-100" v-else>
                                  {{ s.address }} prompt
                                </b>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>

                      <div
                        class="rounded-t-lg bg-cover bg-center h-52 md:h-80 lg:h-80 w-full absolute inset-0 object-cover transition duration-500 ease-in-out opacity-0 hover:opacity-100 mt-0.5 border-b"
                        :style="'background-image: url(' + s.img_2 + ')'"
                      >
                        <div class="h-full w-ful">
                          <div class="flex p-2">
                            <div
                              class="flex border rounded bg-blue-600 bg-opacity-75"
                            >
                              <div class="text-xs p-1 text-gray-100">
                                <i class="fa fa-check-square text-gray-200"></i>
                                {{ s.prompt_type }}
                              </div>
                            </div>
                          </div>

                          <div class="hidden lg:block">
                            <div
                              class="flex items-end justify-center w-full h-72"
                            >
                              <a
                                :href="'/account/' + s.address"
                                class="flex border-2 border-blue-300 p-2 rounded-md bg-gray-700 bg-opacity-90"
                              >
                                <img
                                  src="https://promptdue.com/tokens/BNB.png"
                                  class="w-5"
                                />
                                <b
                                  class="ml-1 text-gray-100"
                                  v-if="s.address != 'free'"
                                >
                                  {{ s.address.slice(0, 5) }} ...
                                  {{
                                    s.address.slice(
                                      s.address.length - 6,
                                      s.address.length - 1
                                    )
                                  }}</b
                                >
                                <b class="ml-1 text-gray-100" v-else>
                                  {{ s.address }} prompt
                                </b>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="bg-gray-900 rounded-b-lg pt-2">
                        <div class="inline-block lg:hidden sm:block p-2">
                          <a
                            class="flex items-end justify-center w-full"
                            :href="'/account/' + s.address"
                          >
                            <div
                              class="flex border-2 border-blue-300 p-1 rounded-md bg-gray-700 bg-opacity-90"
                            >
                              <img
                                src="https://promptdue.com/tokens/BNB.png"
                                class="w-5"
                              />
                              <b
                                class="ml-1 text-gray-100"
                                v-if="s.address != 'free'"
                              >
                                {{ s.address.slice(0, 5) }} ...
                                {{
                                  s.address.slice(
                                    s.address.length - 6,
                                    s.address.length - 1
                                  )
                                }}</b
                              >
                              <b class="ml-1 text-gray-100" v-else>
                                {{ s.address }} prompt
                              </b>
                            </div>
                          </a>
                        </div>

                        <div class="flex-none">
                          <h4
                            class="text-base p-2 text-gray-200"
                            v-if="s.title.length < 30"
                          >
                            ⛵ {{ s.title }}

                            <b
                              v-if="s.title.length < 20"
                              class="inline-block lg:hidden sm:block"
                            >
                              <br />
                              <br />
                            </b>
                          </h4>

                          <h4 class="text-base p-2 text-gray-200" v-else>
                            ⛵ {{ s.title.slice(0, 30) }} ...
                          </h4>
                        </div>

                        <div class="grid grid-cols-2 mt-2 pb-3">
                          <div class="text-center">
                            <h3 class="text-gray-400 text-sm">Price</h3>
                            <h3
                              class="text-lg font-normal font-sans text-gray-100"
                            >
                              $ {{ s.price }}
                            </h3>
                          </div>
                          <div
                            class="flex justify-center items-center text-gray-100"
                          >
                            <div class="text-center">
                              <h3 class="text-sm font-bold">
                                <i class="fas fa-eye"></i>

                                {{ s.view }}

                                <i class="fas fa-heart ml-2"></i>

                                {{ s.favorite }}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center" v-if="promptsNumber > page * 12">
              <br />

              <div
                class="text-xl mt-2 px-6 py-2 border rounded text-yellow-50 hover:bg-gray-500 hover:bg-opacity-25 border-none cursor-pointer"
                v-on:click="getNewPrompts(2)"
              >
                Load more...
              </div>
              <br />
              <br />
            </div>

            <!-- <div class="grid grid-cols-2 gap-2 lg:grid-cols-4 md:grid-cols-4">
              <div
                class="transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer rounded-sm rounded"
                style="margin-top: 5px"
                v-for="s in prompts"
                :key="s"
              >
                <div
                  class="p-0.5 bg-opacity-25 bg-gradient-to-r from-purple-500 via-pink-600 to-purple-300 rounded"
                >
                  <div class="text-sm border-none rounded bg-gray-900">
                    <a class="" target="_blank" :href="linkDetail(s)">
                      <img
                        :src="s.img_1"
                        alt="Image 1"
                        class="inset-0 object-cover ease-in-out opacity-100 hover:opacity-0 h-44 md:h-48 lg:h-60 w-full"
                      />

                      <img
                        :src="s.img_2"
                        style="height: 15.3rem"
                        alt="Image 2"
                        class="absolute inset-0 object-cover transition duration-500 ease-in-out opacity-0 hover:opacity-100 h-40 md:h-40 lg:h-60 w-full"
                      />

                      <div class="flex -mt-7 ml-2">
                        <div class="flex-none">
                          <div
                            class="flex border rounded bg-blue-700 bg-opacity-75"
                          >
                            <div class="text-xs p-1">
                              <i class="fa fa-check-square text-gray-100"></i>
                              {{ s.prompt_type }}
                            </div>
                          </div>
                        </div>

                        <div
                          class="flex-grow text-right text-gray-200 mr-2 hidden lg:block"
                        >
                          <h3 class="text-sm font-bold">
                            <i class="fas fa-eye"></i>

                            {{ s.view }}

                            <i class="fas fa-heart ml-2"></i>

                            {{ s.favorite }}
                          </h3>
                        </div>
                      </div>

                      <div class="flex"></div>

                      <div class="flex bg-black pt-1">
                        <div class="flex-none">
                          <h4 class="text-xs p-2 mt-1 text-gray-200">
                            ⛵ {{ s.title.slice(0, 12) }} ...
                          </h4>
                        </div>

                        <div class="flex-grow p-2 text-right text-gray-200">
                          {{ s.price }}
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";

import Prompt from "@/api/prompt";

import { VERSION } from "../../config";

import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";

// import { products, main_product, products_2 } from "../../products";

export default {
  data() {
    return {
      version: VERSION,
      products: [],
      products_2: [],
      prompts: [],
      showMenu: "hidden lg:block",
      sort: this.$route.params.sort || null,
      textMode: "Trending",
      searchPrompt: null,
      mode: [
        { name: "Free", value: false },
        { name: "Trending", value: true },
        { name: "Popular", value: false },
        { name: "News", value: false },
      ],
      brand: [
        { name: "GPT-3", value: false },
        { name: "ChatGPT", value: false },
        { name: "DALL-E", value: false },
        { name: "Midjourney", value: false },
        { name: "Stable Diffusion", value: false },
        { name: "PromptBase", value: false },
        { name: "BlueWillow", value: false },
      ],
      category: [
        { name: "3D", value: false },
        { name: "Accessory", value: false },
        { name: "Animal", value: false },
        { name: "Anime", value: false },
        { name: "Avatar", value: false },
        { name: "Building", value: false },
        { name: "Buddha", value: false },
        { name: "Cartoon", value: false },
        { name: "Character", value: false },
        { name: "Clothes", value: false },
        { name: "Cute", value: false },
        { name: "Drawing", value: false },
        { name: "Fantasy", value: false },
        { name: "Food", value: false },
        { name: "Future", value: false },
        { name: "Games", value: false },
        { name: "Graphic Design", value: false },
        { name: "Icons", value: false },
        { name: "Jewelry", value: false },
        { name: "Landscape", value: false },
        { name: "Logo", value: false },
        { name: "Mockup", value: false },
        { name: "Monogram", value: false },
        { name: "Nature", value: false },
        { name: "NSFW", value: false },
        { name: "Pattern", value: false },
        { name: "Photography", value: false },
        { name: "Pixel Art", value: false },
        { name: "Product", value: false },
        { name: "Nature", value: false },
        { name: "Psychedelic", value: false },
        { name: "Psychedelic", value: false },
        { name: "Space", value: false },
        { name: "Synthwave", value: false },
        { name: "Unique Style", value: false },
        { name: "Vehicle", value: false },
        { name: "Wallpaper", value: false },
      ],

      addressSearch: null,
      page: 1,
      promptsNumber: 1,
    };
  },
  components: {
    ConnectWallet,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    changeMenu() {
      if (this.showMenu != "") {
        this.showMenu = "";
      } else {
        this.showMenu = "hidden lg:block";
      }
    },
    async getNewPrompts(page = 1) {
      var p = this.mode.filter((m) => {
        return m.value == true;
      });

      var b = this.brand.filter((m) => {
        return m.value == true;
      });

      var brand = "";

      if (b.length > 0) {
        brand = b[0].name;
      } else {
        brand = "all";
      }

      var c = this.category.filter((m) => {
        return m.value == true;
      });

      var categories = "";

      if (c.length > 0) {
        categories = c[0].name;
      } else {
        categories = "all";
      }

      if (page != 1) {
        this.page = this.page + 1;
      } else {
        this.prompts = [];
      }

      await Prompt.filter({
        type: p[0].name,
        brand: brand,
        category: categories,
        address: this.addressSearch,
        page: this.page,
      })
        .then((res) => {
          this.prompts = res.data.prompts;
          this.promptsNumber = res.data.promptsNumber;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeMode(text) {
      this.mode = this.mode.map((m) => {
        if (text == m.name) {
          m.value = true;

          this.textMode = m.name;

          return m;
        } else {
          m.value = false;

          return m;
        }
      });

      this.getNewPrompts();
    },
    linkDetail(s) {
      if (s.price == "free") {
        return "/promptFree/" + s._id;
      } else {
        return "/prompt/" + s._id;
      }
    },
    changeBrand(text) {
      this.brand = this.brand.map((m) => {
        if (text == m.name) {
          if (m.value == true) {
            m.value = false;
          } else {
            m.value = true;
          }

          return m;
        } else {
          m.value = false;

          return m;
        }
      });

      this.getNewPrompts();
    },
    changeCategory(text) {
      this.category = this.category.map((m) => {
        if (text == m.name) {
          if (m.value == true) {
            m.value = false;
          } else {
            m.value = true;
          }

          return m;
        } else {
          m.value = false;

          return m;
        }
      });

      this.getNewPrompts();
    },
    searchPromptByKeywords() {
      if (this.searchPrompt != null) {
        this.addressSearch = this.searchPrompt;

        this.searchPrompt = null;

        this.getNewPrompts();
      } else {
        this.$router.push("/search");

        window.location.reload();
      }
    },
  },
  created() {
    // var array = this.mode.filter((m) => {
    //   return m.toLowerCase() == this.sort.toLowerCase();
    // });

    if (this.sort) {
      var arr = this.mode.filter((m) => {
        return this.sort == m.name;
      });

      if (arr.length > 0) {
        this.mode = this.mode.map((m) => {
          if (this.sort == m.name) {
            m.value = true;

            this.textMode = m.name;

            return m;
          } else {
            m.value = false;

            return m;
          }
        });
      }

      var brandPrompt = this.brand.filter((m) => {
        return this.sort == m.name;
      });

      if (brandPrompt.length > 0) {
        this.brand = this.brand.map((m) => {
          if (this.sort == m.name) {
            m.value = true;

            return m;
          } else {
            m.value = false;

            return m;
          }
        });
      }
    }

    if (this.sort) {
      var aaa = this.mode.filter((m) => {
        return this.sort == m.name;
      });

      if (aaa.length == 0) {
        var brandPrompt = this.brand.filter((m) => {
          return this.sort == m.name;
        });

        if (brandPrompt.length == 0) {
          this.addressSearch = this.sort;
        }
      }
    }

    this.getNewPrompts();
  },
};
</script>
